<template>
  <div class="hello">
    <el-card shadow="always" >

      <el-row :gutter=20 >
        <el-col :span="8"><div class="grid-content ep-bg-purple" />
          <!--        card-->
          <el-card shadow="hover">
            <template #header>
              <div class="card-header">
                <span style="font-weight: bolder;font-family: '仿宋'">设备号信息</span>
              </div>
            </template>
            <!--         input -->
            <el-input size="large" v-model="divce" disabled placeholder="设备号" style="margin-top: 5px"/>
            <el-input size="large" v-model="divceStuts.data.end_at" disabled placeholder="到期时间"  style="margin-top: 8px"/>
            <el-input size="large" v-model="divceStuts.data.msg" disabled placeholder="设备号状态"  style="margin-top: 8px"/>
          </el-card>
        </el-col>
        <el-col :span="16"><div class="grid-content ep-bg-purple-light" />
          <el-card shadow="hover" >
            <template #header>
              <div class="card-header">
                <span style="font-weight: bolder;font-family: '仿宋'">设备搜索</span>
              </div>
            </template>
            <!--    search-->
            <el-input
                size="large"
                v-model="divce"
                placeholder="设备号"
                class="input-with-select"
                @change="blur"
            >
              <template #append>
                <el-icon @click="blur"><Search /></el-icon>
              </template>
            </el-input>

            <!--el-select -->
            <el-select v-model="select" size="large" placeholder="选择时长" style="display: block;margin-top: 10px" >
              <el-option v-for="item in selectTimeOptions" :label="item.lab" :value=item.val />
            </el-select>
            <!--        buttons-->
            <el-row :gutter=20 >
              <el-col :span="12">
                <el-button type="primary" size="large" style="margin-top: 10px;width: 100%;"  @click="addTime">
                  <el-icon class="el-icon--left">
                    <Plus/></el-icon> 添加时间</el-button>
              </el-col>
              <el-col :span="12">
                <el-button type="danger" size="large" style="margin-top: 10px;width: 100%;" @click="deleteDevice">
                  <el-icon class="el-icon--left">
                    <Delete/></el-icon> 删除设备</el-button>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
    </el-card>

      <!--        card-->
      <el-card shadow="hover" :style="{ height: window_height - 345 +'px', marginTop: '20px' }">
        <template #header>
          <div class="card-header">
            <span style="font-weight: bolder;font-family: '仿宋'">设备号历史订单记录</span>
          </div>
        </template>
        <!--          tabe-0-->
        <el-table  :stripe="true" :data="divceTableData" style="width: 100%" :max-height="window_height - 375">
          <el-table-column fixed  prop="timeEnd" label="时间" width="180" :formatter="timeFormatter"/>
          <el-table-column prop="id" label="ID" width="180"/>
          <el-table-column prop="orderId" label="订单号"  />
          <el-table-column prop="totalFee" label="金额（￥分）" />
          <template #empty>
            <el-empty description="无数据" :style="{height: window_height - 415 +'px'}"/>
          </template>
        </el-table>
      </el-card>


  </div>
</template>

<script>
import { Search,Plus,Delete } from '@element-plus/icons-vue'
import {divce, divceStuts,  select,  divceTableData,
  selectTimeOptions, DateValue, blur, addTime, timeFormatter, deleteDevice, window_height } from "../api/api" ;

export default {
  name: 'HelloWorld',
  components: {Search,Plus,Delete},
  setup(){

    return{divce, divceStuts,  select,  divceTableData,
      selectTimeOptions, DateValue, blur, addTime, timeFormatter, deleteDevice, window_height }
  }
}

export class HelloWorld {
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
