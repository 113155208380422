<template>
  <!--        card-->
  <el-card :style="{ height: window_height+'px', }" :body-style="{padding: 0}">
    <iframe src="https://teleph.app"  :style="{width: '100%', height: window_height+'px'}"/>
  </el-card>
</template>

<script>
import {window_width, window_height,} from '@/api/api'
export default {
  name: "TelephView",
  setup(){
    return{window_width, window_height}

  }
}
</script>

<style scoped>

</style>