<template>
  <!--    查询订单-->
  <!--        card-->
  <el-card shadow="always"  :style="{ height: window_height - 45+'px' }">
    <template #header>
      <div class="card-header">
        <span style="font-weight: bolder;font-family: '仿宋'">订单查询</span>
        <span  v-show="orderTableData.length > 0"  style="font-size: 13px;
        font-family: '仿宋';margin-left: 10px">(共搜索到：<span style="color: #9388f8">{{ orderTableData.length}}</span> 条)</span>
      </div>
    </template>
    <!--    search-->
    <el-input
        v-model="orderID"
        placeholder="订单号"
        class="input-with-select"
        @change="orderBlur"

    >
      <template #append>
        <el-icon @click="orderBlur"><Search /></el-icon>
      </template>
    </el-input>
    <!--          tabe-0-->
    <el-table :data="orderTableData"  :stripe="true" :scrollbar-always-on="true" style="width: 100%;margin-top: 20px" :max-height="window_height - 125">
      <el-table-column fixed prop="timeEnd" label="时间" width="180" :formatter="timeFormatter"/>
      <el-table-column fixed prop="imei" label="设备号" width="280" />
      <el-table-column prop="id" label="ID" width="180"/>
      <el-table-column prop="orderId" label="订单号" width="260" />
      <el-table-column prop="totalFee" label="金额（￥分）" />
      <template #empty>
        <el-empty description="无数据" :style="{height: window_height - 165 +'px'}"/>
      </template>
    </el-table>
  </el-card>
</template>

<script>
import { Search} from '@element-plus/icons-vue'
import {orderID, orderTableData, orderBlur, timeFormatter, window_height} from "../api/api" ;

export default {
  name: "orderSearch",
  components: {Search,
    },
  setup(){
    return{
      orderID, orderTableData, orderBlur, timeFormatter, window_height
    }
  }
}
</script>

<style scoped>

</style>