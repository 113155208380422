<template>
  <!--    log tabes-->
  <!--        card-->
  <el-card shadow="always" :style="{ height: window_height - 45 +'px' }">
    <template #header>
      <div class="card-header">
        <span style="font-weight: bolder;font-family: '仿宋'">订单记录</span>
        <el-date-picker
            style="width: 130px;margin-left: 20px"

            v-model="DateValue"
            type="date"
            :placeholder="DateValue"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            :clearable="false"
            :editable="false"
            @change="dateChange"
        >
        </el-date-picker>
        <el-button type="primary"  size="small" :icon="Refresh" circle @click="selectMenu(selectedMenu)" :style="{float: 'right'}"/>
<!--        <span style="margin-left: 10px;font-size: 14px;font-family: '仿宋';font-weight: bolder;margin-top: 10px">[ {{DateValue}} ] -->

          <span style="margin-left: 10px;font-size: 15px;color: #8e83f6">￥ {{tagTotalFreeBD == null ? 0 : tagTotalFreeBD}} </span>
<!--          </span>-->
<!--        <el-divider />-->
        <div style="font-size: 14px;font-family: '仿宋';font-weight: bolder;margin-top: 10px">
          <span>当前可用设备数  <span style="font-size: 13px;color: #8b7ff6">{{TotalAvailableEquipment}}</span></span>
          <el-divider direction="vertical" />
          <span>当月 ￥  <span style="font-size: 13px;color: #9388f8">{{totalFreeBM}}</span></span>
          <el-divider direction="vertical" />
          <span>今日 ￥  <span style="font-size: 13px;color: #8e83f6">{{totalFreeBD == null ? 0 : totalFreeBD}} </span>
          [ <span style="font-size: 13px;color: #8e83f6">{{TodayCount}} </span> 笔 ]
          </span>
        </div>

      </div>
    </template>
    <!--          tabe-0-->
    <el-table :data="tableData"
              :stripe="false"
              :scrollbar-always-on="true"
              style="width: 100%"
              :row-style="rowState"
              :max-height="window_height - 210">
      <el-table-column  prop="timeEnd" label="时间" width="220" :formatter="timeFormatter" />
      <el-table-column  prop="imei" label="设备号" width="280" />
      <el-table-column prop="id" label="ID" />
      <el-table-column prop="orderId" label="订单号" width="260" />
      <el-table-column prop="totalFee" label="金额（￥分）" min-width="120"/>
      <template #empty>
        <el-empty description="无数据" />
      </template>
    </el-table>
    <!--fen ye-->
    <div style="margin-top: 10px;text-align: center">
      <el-pagination
          v-model:currentPage="currentPage"
          v-model:page-size="pageSize"
          :page-sizes="[50, 100, 200, 300, 400]"
          :background="true"
          layout="sizes, prev, pager, next"
          :total="totalSize"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
      />
    </div>
  </el-card>
</template>

<script>
import {Refresh} from '@element-plus/icons-vue'
import {ref, onMounted, watch, onUpdated, onBeforeMount, onActivated, onUnmounted   } from 'vue'
import {tableData,  currentPage, pageSize,  totalSize, dateChange, TodayCount,  selectMenu, selectedMenu,
  totalFreeBD, totalFreeBM,  DateValue, TotalAvailableEquipment, isToday, tagTotalFreeBD,
   handleSizeChange, handleCurrentChange, timeFormatter, window_width, window_height } from "../api/api" ;

export default {
  name: "orders",
  components: {
    Refresh
  },
  setup(){


    const rowState = ({ row }) => {
      if (row.hightLight){
        return {
          backgroundColor: '#313d42',
          color: '#fff'
        }
      }
    }

    return{  tableData,  currentPage, pageSize,  totalSize, dateChange, rowState, TodayCount,  selectMenu, selectedMenu,
      totalFreeBD, totalFreeBM,  DateValue, TotalAvailableEquipment, window_width, tagTotalFreeBD,
     window_height, handleSizeChange, handleCurrentChange, timeFormatter, isToday, Refresh}
  }
}
</script>



<style>
  .el-table .success_class{
    --el-table-tr-bg-color: var(--el-color-success-light-9);
  }
</style>