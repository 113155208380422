import Axios from "axios";
import {ElMessage} from "element-plus";
import {ref} from 'vue'
import {decrypt, encrypt} from '../api/AESUtils'
import clipboard from "clipboard";
import moment from "moment-timezone";
import axios from "axios";

const api = 'https://teleph.app:8984/'
const search_api = 'http://image.teleph.top:8986/'

const proxies_api = 'https://client.teleph.app/api/'

const divce = ref('')
const divceStuts = ref({
    data:{
        end_at:'',
        msg:''
    }
})

const channels = ref({
    data: [],
    numFound: 0
})

const DateValue = ref((new Date()).toLocaleDateString().replaceAll('/','-'))

const currentPage = ref(1)
const pageSize = ref(50)
const totalSize = ref(1000)

const select = ref()

const divceTableData = ref()
const tableData = ref([])
const orderTableData = ref([])

const orderID = ref()

const totalFreeBD = ref(0)
const tagTotalFreeBD = ref(0)
const totalFreeBM = ref(0)

const addTimeStuts = ref()

const TotalAvailableEquipment = ref(0)

const selectTimeOptions = ref([
    {mouth:1, total: 39, val: '0', lab: '月 - 39'},
    {mouth:3, total: 69, val: '1', lab: '季 - 69'},
    {mouth:12, total: 99, val: '2', lab: '年 - 99'},
])

const TodayCount = ref(0)

const handleSizeChange = (val) => {
    // console.log(`${val} items per page`)
    axios_http('get', api+'orders/queryByLimit/'+pageSize.value * (currentPage.value - 1) + '/' + pageSize.value + '/' + 1,null,tableData)
}
const handleCurrentChange =  (val) => {
    // console.log(`current page: ${currentPage.value}`)
    axios_http('get', api+'orders/queryByLimit/'+pageSize.value * (currentPage.value - 1) + '/' + pageSize.value + '/' + 1,null,tableData)
}

const timeFormatter = (row, column, cellValue, index) => {
    // console.log(cellValue, moment.tz(cellValue, 'Asia/Shanghai').format("YYYY-MM-DD hh:mm:ss").toString())
    return moment.tz(cellValue, 'Asia/Shanghai').format("YYYY-MM-DD hh:mm:ss").toString()
}



const blur = () => {
    // console.log(divce.value)
    axios_http('get', api+'xunpay/order_query/'+divce.value,null, divceStuts)
    axios_http('get', api+'orders/queryByImei/'+divce.value,null, divceTableData)
}

const orderBlur = () => {
    // console.log(orderID.value)
    axios_http('get', api+'orders/queryOrderByOne/'+orderID.value,null, orderTableData)
}

const addTime = () => {
    if (!divce.value){
        ElMessage.error('设备号不可为空！！')
        return
    }
    if (!select.value){
        ElMessage.error('未选择时长！！')
        return
    }
    axios_http('post', api+'xunpay/aliCallBack',{
        attach: divce.value+"-"+selectTimeOptions.value[select.value].mouth,
        order_id: "KM",
        out_trade_no: "KM",
        status: "complete",
        time_end: new Date(),
        total_fee: selectTimeOptions.value[select.value].total * 100,
        transaction_id: "KM"
    }, addTimeStuts)
}

const deleteStatus = ref('')

const deleteDevice = () => {
    if (!divce.value){
        ElMessage.error('设备号不可为空！！')
        return
    }
    axios_http('get', api+'xunpay/delete/'+divce.value,null, deleteStatus)
}

const isToday = ( today) => {
    // console.log('cellValue', cellValue)
    // let today = new Date()
    // let cellDay = new Date(cellValue)
    // if (today.getFullYear() == cellDay.getFullYear() && today.getMonth() + 1 == cellDay.getMonth() + 1 && today.getDate() == cellDay.getDate()){
    //     TodayCount.value += 1;
    //     console.log('TodayCount', TodayCount.value)
    //     return true
    // }

    TodayCount.value = 0
    tableData.value.forEach ( (v, i, a) => {
        let tagDay = new Date(today)
        let cellDay = moment.tz(v.timeEnd, 'Asia/Shanghai')
        if (tagDay.getFullYear() == cellDay.year() && tagDay.getMonth() + 1 == cellDay.month() + 1 && tagDay.getDate() == cellDay.date()){
            TodayCount.value += 1
            v.hightLight = true
        }else {
            v.hightLight = false
        }
    })
}

const axios_http = (m,u,d,v, h) => {
    checkLogin()
    if (!container_show.value){
        return
    }
    Axios({
        method: m,
        url: u,
        data: d,
        headers: h
    }).then(res => {
        v.value = res.data
        // console.log('v', v.value)

        switch (v){
            case tableData:
                isToday(new Date());
                // TodayCount.value = 0
                // tableData.value.forEach ( (v, i, a) => {
                //     let today = new Date()
                //     let cellDay = new Date(v.timeEnd)
                //     console.log(v.timeEnd)
                //     console.log(cellDay)
                //     if (today.getFullYear() == cellDay.getFullYear() && today.getMonth() + 1 == cellDay.getMonth() + 1 && today.getDate() == cellDay.getDate()){
                //         TodayCount.value += 1
                //         v.hightLight = true
                //     }else {
                //         v.hightLight = false
                //     }
                // })
                break
            case addTimeStuts:
                if (addTimeStuts.value == 'success'){
                    ElMessage.success('添加时长成功！！')
                }else {
                    ElMessage.error('添加时长失败！！')
                }
                axios_http('get', api+'xunpay/order_query/'+divce.value,null, divceStuts)
                axios_http('get', api+'orders/queryByImei/'+divce.value,null, divceTableData)
                handleCurrentChange()
                break;
            case divceStuts:
                if (divceStuts.value.code == 400){
                    ElMessage.error('未获取设备状态，请检查设备号是否正确！！')
                    divceStuts.value ={
                        data:{
                            end_at:'',
                            msg:''
                        }
                    }
                }
                break;
            case orderTableData:
                if (orderTableData.value.length == 0){
                    ElMessage.error('未查询到此订单信息！！')
                }
                break;
            case channels:
                // console.log(channels.value)
                // channels.value = {}
                let list = res.data.data;
                for (let i = 0; i < list.length; i++) {
                    let item = list[i];
                    let type = item.productType;
                    //名字
                    let name = '';
                    if (Array.isArray((item.productName))) {
                        name = (item.productName)[0];
                    } else {
                        name = (item.productName);
                    }
                    //链接
                    let link = "";
                    if (Array.isArray((item.productDesc))) {
                        link = (item.productDesc)[0];
                    } else {
                        link = (item.productDesc);
                    }
                    //人数
                    let numStr = "";
                    let num = item.productNum;
                    if (num != '' && num != '0k') {
                        numStr = String(num) + "人";
                    }

                    let phImgUrl = item.phImgUrl.startsWith('http') ? item.phImgUrl : 'http://image.teleph.top/image_static/'+item.phImgUrl

                    let it = {id: item.id, title: name, link: link, num: numStr, type: type, phImgUrl: phImgUrl};
                    if (i == 0) {
                        channels.value.data = [];
                        channels.value.numFound = res.data.numFound
                    }
                    channels.value.data.push(it);
                }

                break;
            case deleteStatus:
                if (deleteStatus.value.code == 200){
                    if (deleteStatus.value.data){
                        ElMessage.success(deleteStatus.value.msg)
                        divceStuts.value ={
                            data:{
                                end_at:'',
                                msg:''
                            }
                        }
                    }else {
                        ElMessage.error(deleteStatus.value.msg)
                    }
                }else {
                    ElMessage.error('设备号不存在！')
                }
                break
            case appConfig:
                let date = decrypt(res.data)
                appConfig.value = JSON.parse(date).content
                break
            case proxyData:
                let data = decrypt(res.data)
                proxyData.value = JSON.parse(data).content
                checkMobile()
                break
            case appStatus:
                appStatus.value = JSON.parse(decrypt(res.data)).content
                break
            case appKeys:
                appKeys.value = JSON.parse(decrypt(res.data)).content
                break
            case appUpdates:
                appUpdates.value = JSON.parse(decrypt(res.data)).content
                break
            case putAppConfigStats:
                putAppConfigStats.value = JSON.parse(decrypt(res.data))
                if (putAppConfigStats.value){
                    appBackLoad()
                    ElMessage({
                        type: 'success',
                        message: '修改成功',
                    })
                } else {
                    ElMessage({
                        type: 'info',
                        message: '修改失败',
                    })
                }
                break
            case deleteConfigStats:
                deleteConfigStats.value = JSON.parse(decrypt(res.data))
                if (deleteConfigStats.value){
                    appBackLoad()
                    ElMessage({
                        type: 'success',
                        message: '已删除',
                    })
                } else {
                    ElMessage({
                        type: 'info',
                        message: '删除失败',
                    })
                }
                break
            case addConfigStats:
                addConfigStats.value = JSON.parse(decrypt(res.data))
                if (addConfigStats.value){
                    appBackLoad()
                    ElMessage({
                        type: 'success',
                        message: '已添加',
                    })
                } else {
                    ElMessage({
                        type: 'info',
                        message: '添加失败',
                    })
                }
                break
            case proxies:
                proxies.value = []
                proxies.value = res.data
                // console.log('proxies', proxies.value)
                pingProxiesHttp()
                checkMobile()
                break
            case pingProxies:
                pingProxies.value = new Map()
                res.data.forEach((v) => {
                    pingProxies.value.set(v.id, v.seconds)
                    Proxies_Map.value.get(v.id).seconds = v.seconds
                })
                break
            case deleteProxiesStatus:
                queryProxies()
                deleteProxiesStatus.value = res.data
                deleteProxies_list.value = []
                if (deleteProxiesStatus.value){
                    ElMessage({
                        type: 'success',
                        message: '已删除',
                    })
                } else {
                    ElMessage({
                        type: 'info',
                        message: '删除失败',
                    })
                }
                // console.log('deleteProxiesStatus', res.data)
                break
            case addProxiesStatus:
                queryProxies()
                addProxiesStatus.value = res.data
                if (addProxiesStatus.value){
                    ElMessage({
                        type: 'success',
                        message: '已添加',
                    })

                    addProxies.value = []
                    addProxiesDialogShow.value = false
                } else {
                    ElMessage({
                        type: 'info',
                        message: '添加失败',
                    })
                }
                // console.log('deleteProxiesStatus', addProxiesStatus.value)
                break
            case enableProxyStatus:
                queryProxies()
                enableProxyStatus.value = res.data
                if (enableProxyStatus.value){
                    ElMessage({
                        type: 'success',
                        message: '已启用',
                    })

                    addProxies.value = []
                    addProxiesDialogShow.value = false
                } else {
                    ElMessage({
                        type: 'info',
                        message: '启用失败',
                    })
                }
                // console.log('enableProxyStatus', enableProxyStatus.value)
                break
            case editProxyStatus:
                queryProxies()
                editProxyStatus.value = res.data
                if (editProxyStatus.value){
                    ElMessage({
                        type: 'success',
                        message: '已修改',
                    })

                    editProxyDialogShow.value = false
                } else {
                    ElMessage({
                        type: 'info',
                        message: '修改失败',
                    })
                }
                // console.log('editProxyStatus', editProxyStatus.value)
                break
        }
    }).catch(err => {
        console.log(err)
        ElMessage({
            type: 'info',
            message: '请求失败',
        })
    })
}

const appBackLoad = () => {
    appConfig.value = [];
    proxyData.value = [];
    appStatus.value = [];
    appKeys.value = [];
    appUpdates.value = [];
    queryAppConfig()
    querProxyData()
    querAppKeys()
    querAppStatus()
    querAppUpdates()
}


const dateChange = (val) => {
    let valList = val.split('-')
    // let date = valList[0]+'-'+valList[1]+'-'+'0'
    // console.log('dateChange', new Date().toLocaleString().replaceAll('/','-'))
    // isToday(val)

    axios_http('get', api+'orders/queryTotalFeeByDate/'+val,null, tagTotalFreeBD)
    axios_http('get', api+'orders/queryTotalFeeByDate/'+new Date().toLocaleString().replaceAll('/','-').split(' ')[0],
        null, totalFreeBD)
    axios_http('get', api+'orders/queryTotalFeeByDate/'+valList[0]+'-'+valList[1]+'-' + '0',null, totalFreeBM)
}

const selectedMenu = ref('1')

const baseSideShow = ref(true)
const OrderSearch_show = ref(false)
const HelloWorld_show = ref(true)
const Orders_show = ref(false)
const channelSearch_show = ref(false)
const NewDevices_show = ref(false)
const ExpiredDevice_show = ref(false)
const appBackView_show = ref(false)
let Teleph_show = ref(false)
const ProxiesView_show = ref(false)
const addProxiesDialogShow = ref(false)



const turnFalse = () => {
    OrderSearch_show.value = false
    HelloWorld_show.value = false
    Orders_show.value = false
    channelSearch_show.value = false
    appBackView_show.value = false
    NewDevices_show.value = false
    ExpiredDevice_show.value = false
    Teleph_show.value = false
    ProxiesView_show.value = false
}

const window_width = ref(document.documentElement.clientWidth)

const window_height = ref(document.documentElement.clientHeight - 35)

const selectMenu = (val) => {
    // console.log(val)
    selectedMenu.value = val
    turnFalse()
    switch (val) {
        case '0':
            Teleph_show.value = true
            break
        case '1':
            HelloWorld_show.value = true
            break
        case '2':
            OrderSearch_show.value = true
            break
        case '3':
            Orders_show.value = true

            axios_http('get', api+'orders/queryAllCount',null, totalSize)
            // axios_http('get', api+'orders/queryTotalFeeByD',null, totalFreeBD)
            // axios_http('get', api+'orders/queryTotalFeeByM',null, totalFreeBM)
            axios_http('get', api+'orders/queryTotalAvailableEquipment',null, TotalAvailableEquipment)
            dateChange(DateValue.value)
            handleCurrentChange()
            break
        case '4':
            channelSearch_show.value = true
            break
        case '5':
            queryDeviceByDate()
            NewDevices_show.value = true
            break
        case '6':
            queryExpiredDeviceByNow()
            ExpiredDevice_show.value = true
            break
        case '7':
            appBackLoad()
            appBackView_show.value = true
            break
        case '8':
            queryProxies()
            ProxiesView_show.value = true
            break
    }

    // console.log( HelloWorld_show.value, OrderSearch_show.value, Orders_show.value, channelSearch_show.value)
}

const newDeviceDate = ref((new Date()).toLocaleDateString().replaceAll('/', '-'))

const newDeviceList = ref({data: []})
const expiredDeviceList = ref({numFound: 0, data: []})

const queryDeviceByDate = () => {
    axios_http('get', api+'xunpay/queryDeviceByDate/'+newDeviceDate.value+'/1',null, newDeviceList)
}

const newDeviceDateChange = (val) => {
    queryDeviceByDate()
}

const expired_currentPage = ref(1)
const expired_pageSize = ref(50)
const expired_handleSizeChange = () => {
    queryExpiredDeviceByNow()
}
const expired_handleCurrentChange = () => {
    queryExpiredDeviceByNow()
}

const ExpiredDeviceOrder = ref(1)

const reloadList = () => {
    ExpiredDeviceOrder.value = (ExpiredDeviceOrder.value + 1) % 2
    queryExpiredDeviceByNow()
}

const appConfig = ref([])
const proxyData = ref([])
const appStatus = ref([])
const appKeys = ref([])
const appUpdates = ref([])
const proxies = ref([])
const deleteProxiesStatus = ref({})
const pingProxies = ref(new Map())
const addProxiesStatus = ref({})
const enableProxyStatus = ref({})
const deleteProxies_list = ref([])

const queryProxies = async () => {
    await querProxyData()
    await axios_http('get', proxies_api+'proxy', null, proxies)
}

const checkMobile = () => {
    if (proxyData.value.length > 0 && proxies.value.length > 0){
        proxies.value.forEach(proxy => {
            proxyData.value.forEach(data => {
                if (proxy.server === data.poxyaddress && proxy.port === data.poxyport){
                    proxy.mobile = true
                }
            })
        })
    }
}

const deleteProxies = (ids) => {
    axios_http('DELETE', proxies_api+'proxy', ids, deleteProxiesStatus, {'Content-Type' :'application/json'})
}

const Proxies_Map = ref(new Map())
const pingProxiesHttp = async () => {
    checkLogin()
    const pingPromises = proxies.value.map(proxy => Axios({
        method: 'POST',
        url: proxies_api + 'proxy/ping',
        data: JSON.stringify([proxy.id]), // 注意这里你可能需要将数据转换为JSON字符串
        timeout: 20000,
        headers: {'Content-Type': 'application/json'}
    }).then(response => {
        // 在这里处理每个请求的成功响应
        const data = response.data[0];
        if (data) {
            // 更新proxies.value中的相应项
            const index = proxies.value.findIndex(v => v.id === data.id);
            if (index !== -1) {
                proxies.value[index].seconds = data.seconds;
            }
        }
    }).catch(error => {
        // 在这里处理每个请求的失败情况
        console.error(`Request for proxy ${proxy.id} failed:`, error);
    }));

    // 等待所有请求完成（无论成功还是失败）
    try {
        await Promise.all(pingPromises);
        // 所有请求都已完成，可以在这里执行一些后续操作
    } catch (error) {
        // 注意：这里通常不会捕获到所有请求的错误，因为每个请求都有自己的catch处理
        // 但如果Promise.all自身出错（例如内存溢出），这里的catch会捕获到
        console.error('Promise.all failed:', error);
    }

}

const enableProxy = (id) => {
    axios_http('post', proxies_api+'proxy/enable', id, enableProxyStatus, {'Content-Type' :'application/json'})
}


const editProxyDialogShow = ref(false)
const editProxy = ref({})
const editProxyStatus = ref({})
const editProxySubmit = () => {
    axios_http('post', proxies_api+'proxy/edit', editProxy.value, editProxyStatus, {'Content-Type' :'application/json'})
}
const editProxyHandler = (scope) => {
    let item = scope.row
    editProxy.value = {
        "id": item.id,
        "server": item.server,
        "port": item.port,
        "enable": item.isEnabled,
        "_proxyType":{
            "secret": item.type.secret,
            "username": item.type.username ? item.type.username : "",
            "password": item.type.password ? item.type.password : "",
        }
    }
    // console.log('editProxy',editProxy.value)
    editProxyDialogShow.value = true
}

const addProxies = ref([])

const submitProxies = () => {
    let list = addProxies.value.split('https://t.me/').slice(1)
    let addProxies_list = []
    list.forEach((v, i) => {
        const params = {
            "enable": false
        };
        const url_strs = v.split('?')
        const search = url_strs[1];

        if (search) {
            search.split('&').forEach(param => {
                const [key, value] = param.split('=');
                params[key] = value.replace(/\n/g, "");  ;
            });
        }

        if (url_strs[0] === 'proxy'){
            params['_proxyType'] = {
                'secret': params['secret']
            };
            delete params['secret']
        }else if (url_strs[0] === 'socks'){
            params['_proxyType'] = {
                'username': params['username'] ? params['username'] : "",
                'password': params['password'] ? params['password'] : ""
            };
        }

        addProxies_list.push(params)
    })
    // console.log('addProxies_list', addProxies_list)
    axios_http('PUT', proxies_api+'proxy', addProxies_list, addProxiesStatus, {'Content-Type' :'application/json'})
}


const queryAppConfig = () => {
    axios_http('get', api+'appConfig', null, appConfig)
}

const querProxyData = () => {
    axios_http('get', api+'proxyData', null, proxyData)
}

const querAppStatus = () => {
    axios_http('get', api+'appKeys', null, appKeys)
}

const querAppKeys = () => {
    axios_http('get', api+'appStatus', null, appStatus)
}

const querAppUpdates = () => {
    axios_http('get', api+'appUpdates', null, appUpdates)
}

const putAppConfigStats = ref()

const headers = {
    'Content-Type': 'multipart/form-data'
}
const putAppConfig = (url, data) => {
    axios_http('put', api+url, data, putAppConfigStats,)
}

const deleteConfigStats = ref()
const deleteConfig = (url, data) => {
    axios_http('delete', api+url, data, deleteConfigStats, headers)
}

const addConfigStats = ref()
const addConfig = (url, data) => {
    axios_http('post', api+url, data, addConfigStats, )
}

const queryExpiredDeviceByNow = () => {
    axios_http('get', api+'xunpay/queryExpiredDeviceByNow/'+ExpiredDeviceOrder.value+'/'+ expired_pageSize.value * (expired_currentPage.value - 1) +
        '/' + expired_pageSize.value,null, expiredDeviceList)
}

const me_admin = 'admin'
const me_pass = 'Teleph'

const App_user = 'Teleph'
const App_passd = 'teleph.app'

export const user = ref('')
export const pass = ref('')

export const container_show = ref(false)
export const index_show = ref(true)

const copy = async (id, text) => {
    const clipboard1 = new clipboard(id, {
        text: function () {
            return text
        }
    });
    await clipboard1.on("success", () => {
        ElMessage.success('内容已复制到剪贴板!')
        // 释放内存
        clipboard1.destroy();
    });
    await clipboard1.on("error", () => {
        ElMessage.error('内容复制失败！')
        // 释放内存
        clipboard1.destroy();
    });
}
export const checkLogin = () => {
    // console.log('checkLogin')
    try {
        // let sess_user = sessionStorage.getItem('user')
        // let sess_pass = sessionStorage.getItem('pass')
        let session_strs = decrypt(sessionStorage.getItem('key')).split('-')
        let sess_time = parseInt(session_strs[0], 16)
        AppConfigViewShow.value = session_strs[1] == 'true' ? true : false

        if (Number(sess_time) + 1000 * 60 * 30 > new Date().getTime()){

            index_show.value = false
            container_show.value = true
        }else {
            index_show.value = true
            container_show.value = false
            if (sess_time){
                ElMessage.error('登录过期，请重新登录！！')
            }
            sessionStorage.removeItem('key')
        }

    }catch (err){
        index_show.value = true
        container_show.value = false
    }

}

const AppConfigViewShow = ref(false)

export const conf = () => {
    let me = user.value === me_admin && pass.value === me_pass
    let app = AppConfigViewShow.value = user.value === App_user && pass.value === App_passd
    if (me || app){
        index_show.value = false
        container_show.value = true
        // sessionStorage.setItem('user', user.value)
        // sessionStorage.setItem('pass', pass.value)
        sessionStorage.setItem('key', encrypt(new Date().getTime().toString(16)+ '-' + AppConfigViewShow.value))
        user.value = ''
        pass.value = ''


        // AppConfigViewShow.value = app;
    }else {
        ElMessage.error('账号或密码错误！！')
    }
    // console.log('conf.AppConfigViewShow', AppConfigViewShow.value)
}

const drawer = ref(false)

export {api, select, selectTimeOptions, addTimeStuts, addTime, handleCurrentChange, dateChange, currentPage, blur, pageSize, DateValue,
    divce, divceStuts, tableData, divceTableData, orderTableData, orderID, orderBlur, axios_http, Axios, handleSizeChange, timeFormatter,
    TotalAvailableEquipment, totalFreeBD, totalFreeBM, totalSize, ElMessage, selectedMenu, selectMenu, OrderSearch_show, HelloWorld_show, turnFalse,
    Orders_show, channelSearch_show, search_api, channels, deleteDevice, NewDevices_show, window_width, window_height, newDeviceList, newDeviceDate,
    newDeviceDateChange, ExpiredDevice_show, expiredDeviceList, expired_handleCurrentChange, expired_currentPage, expired_pageSize, expired_handleSizeChange,
    Teleph_show, reloadList, isToday, baseSideShow, TodayCount, drawer, tagTotalFreeBD, appBackView_show, appConfig, queryAppConfig, proxies,
    proxyData, querProxyData, appKeys, appStatus, putAppConfig, appUpdates, deleteConfig, addConfig, AppConfigViewShow, ProxiesView_show, copy, deleteProxies,
    addProxiesDialogShow, submitProxies, addProxies, enableProxy, editProxy, editProxySubmit, editProxyHandler, editProxyDialogShow, pingProxiesHttp,
    deleteProxies_list
}
