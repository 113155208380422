<template>
  <!--        card-->
  <el-card shadow="always"  :style="{ height: window_height - 45+'px',  overflowY: 'auto', }">
    <template #header>
      <div class="card-header">
        <span style="font-weight: bolder;font-family: '仿宋',serif">App 配置</span>
        <el-button type="primary"  size="small" :icon="Refresh" circle @click="selectMenu(selectedMenu)" :style="{float: 'right'}"/>
      </div>
    </template>

    <el-row :gutter="20">
      <el-col :span="9">
        <!--      基本配置  card-->
        <el-card shadow="hover">
          <template #header>
            <div class="card-header">
              <span style="font-weight: bolder;font-family: '仿宋',serif">基本配置</span>
              <el-button type="success"  size="small" :icon="Plus" @click="handerAdd('appConfig')" :style="{float: 'right'}"/>
            </div>

          </template>
          <el-table :data="appConfig" style="width: 100%" height="530" >
            <el-table-column prop="configKey" label="configKey" width="170" />
            <el-table-column prop="configVal" label="configVal"  />
            <el-table-column  label="Ops" width="60">
              <template #default="scope" >
                <!--                <el-button link type="primary" size="small">Delete</el-button >-->
                <el-button link type="primary" @click="handleEditClick(scope.row, 'appConfig')" size="small">Edit</el-button>
              </template>
            </el-table-column>
          </el-table>

        </el-card>

        <br/>
        <!--      App  KEYS  card-->
        <el-card shadow="hover" >
          <template #header>
            <div class="card-header">
              <span style="font-weight: bolder;font-family: '仿宋',serif">App状态</span>
              <el-button type="success"  size="small" :icon="Plus" @click="handerAdd('appStatus')"  :style="{float: 'right'}"/>
            </div>
          </template>


          <el-table :data="appStatus" style="width: 100%" height="110" >
            <el-table-column prop="apiId" label="apiId"  />
            <el-table-column prop="disApp" label="disApp"  />
            <el-table-column prop="showchannel" label="showchannel"  />
            <el-table-column  label="Operations" width="120">
              <template #default="scope">
                <el-button link type="primary" size="small"  @click="handlerDelete(scope.row, 'appStatus')"
                >Delete</el-button
                >
                <el-button link type="primary" @click="handleEditClick(scope.row, 'appStatus')" size="small">Edit</el-button>
              </template>
            </el-table-column>
          </el-table>

        </el-card>

      </el-col>
      <el-col :span="15">
        <!--      App  Proxy配置  card-->
        <el-card shadow="hover">
          <template #header>
            <div class="card-header">
              <span style="font-weight: bolder;font-family: '仿宋',serif">Proxy配置</span>
              <el-button type="success"  size="small" :icon="Plus" @click="handerAdd('proxyData')"  :style="{float: 'right'}"/>
            </div>
          </template>

          <el-table :data="proxyData" style="width: 100%" height="560" >
            <el-table-column prop="poxyid" label="ID" width="40"/>
            <el-table-column prop="poxytype" label="Type" width="60"/>
            <el-table-column prop="poxyaddress" label="poxyaddress" width="120" />
            <el-table-column prop="poxyport" label="port" width="60" />
            <el-table-column prop="poxysecret" label="poxysecret" min-width="400"  />
            <el-table-column prop="username" label="user"/>
            <el-table-column prop="password" label="pass"  />
            <el-table-column  label="Operations" width="120">
              <template #default="scope">
                <el-button link type="primary" size="small" @click="handlerDelete(scope.row, 'proxyData')"
                >Delete</el-button
                >
                <el-button link type="primary" @click="handleEditClick(scope.row, 'proxyData')" size="small">Edit</el-button>
              </template>
            </el-table-column>
          </el-table>


        </el-card>

        <br/>
        <!--     App  KEYS  card-->
        <el-card shadow="hover" >
          <template #header>
            <div class="card-header">
              <span style="font-weight: bolder;font-family: '仿宋',serif">App  KEYS</span>
              <el-button type="success"  size="small" :icon="Plus" @click="handerAdd('appKeys')"  :style="{float: 'right'}"/>
            </div>
          </template>

          <el-table :data="appKeys" style="width: 100%" height="80" >
            <el-table-column prop="apiId" label="apiId" min-width="30" />
            <el-table-column prop="apiHash" label="apiHash"  />
            <el-table-column  label="Operations" width="120">
              <template #default="scope">
                <el-button link type="primary" size="small" @click="handlerDelete(scope.row, 'appKeys')"
                >Delete</el-button
                >
                <el-button link type="primary" @click="handleEditClick(scope.row, 'appKeys')" size="small">Edit</el-button>
              </template>
            </el-table-column>
          </el-table>

        </el-card>
      </el-col>
    </el-row>

    <br/>
    <!--      App  Proxy配置  card-->
    <el-card shadow="hover" >
      <template #header>
        <div class="card-header">
          <span style="font-weight: bolder;font-family: '仿宋',serif">Update</span>
          <!--          <el-button type="success"  size="small" :icon="Plus"  :style="{float: 'right'}"/>-->
        </div>
      </template>

      <el-table :data="appUpdates" style="width: 100%" height="130" >
        <el-table-column prop="updateId" label="ID" width="40"/>
        <el-table-column prop="title" label="title" width="120"/>
        <el-table-column prop="isUpdate" label="Update" width="90" />
        <el-table-column prop="newVersion" label="new" width="80" />
        <el-table-column prop="newMd5" label="newMd5" width="300"  />
        <el-table-column prop="apkFileUrl" label="Url" width="680"/>
        <el-table-column prop="updateLog" label="Log"  />
        <el-table-column prop="targetSize" label="Size"  />
        <el-table-column prop="isConstraint" label="Constraint"  />
        <el-table-column prop="apptype" label="type"  />
        <el-table-column  label="Ops" width="60">
          <template #default="scope">
            <!--            <el-button link type="primary" size="small"-->
            <!--            >Delete</el-button-->
            <!--            >-->
            <el-button link type="primary" @click="handleEditClick(scope.row, 'appUpdates')" size="small">Edit</el-button>
          </template>
        </el-table-column>
      </el-table>

    </el-card>
  </el-card>


  <!--  #####################################################################################################################-->
  <!--dialog  put-->
  <el-dialog v-model="dialogTableVisible" title="Edit" width="30%" @close="handlerEditClose">
    <el-form :model="changeValue" >
      <!--   appConfig   -->
      <div  v-if="showType === 'appConfig'">
        <el-form-item>
          <el-input v-model="changeValue.configVal" :style="{textAlign: 'center'}">
            <template #prepend>{{changeValue.configKey}}</template>
          </el-input>
        </el-form-item>
      </div>

      <!--proxyData-->
      <div v-if="showType === 'proxyData'">
        <el-form-item>
          <el-input v-model="changeValue.proxyname" :style="{textAlign: 'center'}">
            <template #prepend>proxyname</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="changeValue.poxytype" :style="{textAlign: 'center'}">
            <template #prepend>poxytype</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="changeValue.poxyaddress" :style="{textAlign: 'center'}">
            <template #prepend>poxyaddress</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="changeValue.poxyport" :style="{textAlign: 'center'}">
            <template #prepend>poxyport</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="changeValue.poxysecret" type="textarea" :style="{textAlign: 'center'}">
            <template #prepend>poxysecret</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="changeValue.username" :style="{textAlign: 'center'}">
            <template #prepend>username</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="changeValue.password" :style="{textAlign: 'center'}">
            <template #prepend>password</template>
          </el-input>
        </el-form-item>
      </div>
      <!--appStatus-->
      <div v-if="showType === 'appStatus'">
        <el-form-item>
          <el-input v-model="changeValue.apiId" disabled :style="{textAlign: 'center'}">
            <template #prepend>apiId</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="changeValue.disApp" :style="{textAlign: 'center'}">
            <template #prepend>disApp</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="changeValue.showchannel" :style="{textAlign: 'center'}">
            <template #prepend>showchannel</template>
          </el-input>
        </el-form-item>
      </div>

      <!--     appKeys -->
      <div v-if="showType === 'appKeys'">
        <el-form-item >
          <el-input v-model="changeValue.apiHash" :style="{textAlign: 'center'}">
            <template #prepend>{{changeValue.apiId}}</template>
          </el-input>
        </el-form-item>
      </div>

      <!--appUpdates-->
      <div v-if="showType === 'appUpdates'">
        <el-form-item>
          <el-input v-model="changeValue.title" :style="{textAlign: 'center'}">
            <template #prepend>Title</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="changeValue.isUpdate" :style="{textAlign: 'center'}">
            <template #prepend>isUpdate</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="changeValue.newVersion" :style="{textAlign: 'center'}">
            <template #prepend>newVersion</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="changeValue.newMd5" :style="{textAlign: 'center'}">
            <template #prepend>newMd5</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="changeValue.apkFileUrl" type="textarea" :style="{textAlign: 'center'}">
            <template #prepend>apkFileUrl</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="changeValue.updateLog" :style="{textAlign: 'center'}">
            <template #prepend>updateLog</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="changeValue.targetSize" :style="{textAlign: 'center'}">
            <template #prepend>targetSize</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="changeValue.isConstraint" :style="{textAlign: 'center'}">
            <template #prepend>isConstraint</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="changeValue.apptype" disabled :style="{textAlign: 'center'}">
            <template #prepend>apptype</template>
          </el-input>
        </el-form-item>
      </div>

      <el-form-item>
        <el-button type="primary" :style="{position: 'absolute', right: '0%', top: '1%'}" @click="onSubmit">Submit</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>

  <!-- ##################################################################################################################### -->
  <!--dialog  add-->
  <el-dialog v-model="addDialogTableVisible" title="ADD" width="30%" @close="handlerAddClose">
    <el-form :model="addVal" >
      <!--   appConfig   -->
      <div  v-if="showAddViewType === 'appConfig'">
        <el-form-item>
          <el-input v-model="addVal.configKey" :style="{textAlign: 'center'}">
            <template #prepend>configKey</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="addVal.configVal" :style="{textAlign: 'center'}">
            <template #prepend>configVal</template>
          </el-input>
        </el-form-item>
      </div>

      <!--proxyData-->
      <div v-if="showAddViewType === 'proxyData'">
        <el-form-item>
          <el-input v-model="addVal.proxyname" :style="{textAlign: 'center'}">
            <template #prepend>proxyname</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="addVal.poxytype" :style="{textAlign: 'center'}">
            <template #prepend>poxytype</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="addVal.poxyaddress" :style="{textAlign: 'center'}">
            <template #prepend>poxyaddress</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="addVal.poxyport" :style="{textAlign: 'center'}">
            <template #prepend>poxyport</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="addVal.poxysecret" type="textarea"  placeholder="poxysecret" :style="{textAlign: 'center'}">
            <template #prepend>poxysecret</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="addVal.username" :style="{textAlign: 'center'}">
            <template #prepend>username</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="addVal.password" :style="{textAlign: 'center'}">
            <template #prepend>password</template>
          </el-input>
        </el-form-item>
      </div>

      <!--appStatus-->
      <div v-if="showAddViewType === 'appStatus'">
        <el-form-item>
          <el-input v-model="addVal.apiId" :style="{textAlign: 'center'}">
            <template #prepend>apiId</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="addVal.disApp" :style="{textAlign: 'center'}">
            <template #prepend>disApp</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="addVal.showchannel" :style="{textAlign: 'center'}">
            <template #prepend>showchannel</template>
          </el-input>
        </el-form-item>
      </div>

      <!--     appKeys -->
      <div v-if="showAddViewType === 'appKeys'">
        <el-form-item >
          <el-input v-model="addVal.apiId" :style="{textAlign: 'center'}">
            <template #prepend>apiId</template>
          </el-input>
        </el-form-item>
        <el-form-item >
          <el-input v-model="addVal.apiHash" :style="{textAlign: 'center'}">
            <template #prepend>apiHash</template>
          </el-input>
        </el-form-item>
      </div>


      <el-form-item>
        <el-button type="primary" :style="{position: 'absolute', right: '0%', top: '1%'}" @click="onAddSubmit">Submit</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>

</template>

<script>
import {window_height, appConfig, proxyData, appStatus, appKeys, appUpdates, putAppConfig, deleteConfig, selectMenu,
  selectedMenu, addConfig} from '@/api/api'
import {ref} from "vue";
import {Check, Plus, Refresh} from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
  name: "appBackView",
  components: {Check, Plus, Refresh},
  setup () {

    const dialogTableVisible = ref(false)
    const changeValue = ref()
    const showType = ref()

    const addDialogTableVisible = ref(false)
    const addVal = ref({})
    const showAddViewType = ref()

    const handleEditClick = (row, type) =>{
      dialogTableVisible.value = true
      changeValue.value = row
      showType.value = type
      // console.log(row, type)
    }

    const deleteP = ref()
    const urlStr = ref()
    const handlerDelete = (row, type) => {
      deleteP.value = row
      urlStr.value = type

      ElMessageBox.confirm(
          '您确认要删除 ?',
          'Delete',
          {
            confirmButtonText: 'OK',
            cancelButtonText: '取消',
            type: 'warning',
          }
      )
          .then(() => {

            let data = {
              id: null
            }
            switch (type) {
              case 'proxyData':
                data.id = row.poxyid;
                break
              case 'appStatus':
                data.id = row.apiId;
                break
              case 'appKeys':
                data.id = row.appKeyId;
                break
            }
            deleteConfig(type, data)

          })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: '删除取消',
            })
          })
    }

    const onSubmit = () => {
      let data = {}
      switch (showType.value){
        case 'appConfig':
          data = {
            configKey: changeValue.value.configKey,
            configVal: changeValue.value.configVal,
            createdAt: null,
            updatedAt: new Date()
          }
          break
        case 'proxyData':
          data = {
            poxyid: changeValue.value.poxyid,
            proxyname: changeValue.value.proxyname,
            poxyaddress: changeValue.value.poxyaddress,
            poxytype: changeValue.value.poxytype,
            poxyport: changeValue.value.poxyport,
            poxysecret: changeValue.value.poxysecret,
            username: changeValue.value.username,
            password: changeValue.value.password
          }
          break
        case 'appStatus':
          data = {
            apiId: changeValue.value.apiId,
            disApp: changeValue.value.disApp,
            showchannel: changeValue.value.showchannel,
          }
          break
        case 'appKeys':
          data = {
            appKeyId: changeValue.value.appKeyId,
            apiId: changeValue.value.apiId,
            apiHash: changeValue.value.apiHash,
          }
          break
        case 'appUpdates':
          data = {
            updateId: changeValue.value.updateId,
            title: changeValue.value.title,
            isUpdate: changeValue.value.isUpdate,
            newVersion: changeValue.value.newVersion,
            newMd5: changeValue.value.newMd5,
            apkFileUrl: changeValue.value.apkFileUrl,
            updateLog: changeValue.value.updateLog,
            targetSize: changeValue.value.targetSize,
            isConstraint: changeValue.value.isConstraint,
            apptype: changeValue.value.apptype,
          }
      }

      putAppConfig(showType.value, data)
      dialogTableVisible.value = false
      showType.value = ''
    }

    const handerAdd = (type) => {
      showAddViewType.value = type
      addDialogTableVisible.value = true
    }

    const onAddSubmit = () => {
      switch (showAddViewType.value){
        case 'proxyData':
          addVal.value.proxyimg = 1
          addVal.value.poxyid = proxyData.value.length + 1
          break
      }
      addVal.value.createdAt = new Date()
      addVal.value.updatedAt = new Date()
      addConfig(showAddViewType.value, addVal.value)
      addDialogTableVisible.value = false

    }

    const handlerAddClose = () => {
      showAddViewType.value = ''
      addVal.value = {}
    }

    const handlerEditClose = () => {
      showType.value = ''
      changeValue.value = {}
    }

    return {
      window_height, appConfig, proxyData, appStatus, appKeys, Check, Plus, handleEditClick, dialogTableVisible, changeValue,
      onSubmit, showType, appUpdates, handlerDelete, Refresh, selectMenu, selectedMenu, addDialogTableVisible, addVal,
      showAddViewType, handerAdd, onAddSubmit, handlerAddClose, handlerEditClose
    }
  }
}
</script>

<style scoped>
.card-body{
  overflow-y: auto;
  height: 850px;
}
</style>