<template>
  <div :style="{backgroundColor: '#1f1f1f',  paddingTop: '10px', paddingBottom: '5px'}">
    <el-icon :style="{float: 'left', marginLeft: '20px', fontSize: '25px', color: '#ffffff8e'}"
    @click="drawer = true"><Fold /></el-icon>
    <svg
        style="margin-right: 15px;"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="18px" height="15px">
      <image  x="0px" y="0px" width="18px" height="15px"  xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPCAMAAADeWG8gAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABs1BMVEWRbsqMc86Jd9CFe9OAf9Z8g9l5htx2it5yjuBvkOJslOVpl+dmmemaZMObZMOfYMCiXb+iXb6kW72mWbuhXr+sU7ezTbO1SrC5Rq+3R6/DO6a+QKrDPKbJNqLHN6PKNaFjnetinuxgoO1goO1goO1pl+dmmellm+plm+tlm+pvkOJslORrleZrlOV5htx2it50jd9zjN9zjd9zjN90jeCAf9Z8g9l7hNp6hdt8g9p7hNl6hduJd9CGetKEe9SDfNSEetOFetOFetODfNSRbsqOccyNcs2Nc82Occ2UbMiPcMyNcs2XZ8aYZ8WVasiVasidYsKZZsSXaMWUa8ibY8OfYMChXr+iXb+iXb+hXr+dYsKmWbqiXb6hXr+hXr+kW72mWbujXL6sU7asUreqVLipVbm0S7G0S7KzS7K3R668Qqy6RKy+QKrDPabEO6bDO6bHN6PKNKHKNKHJNqLMM6DPL57PL57OMJ7KNaFlm+prleZzjeBzjN9zjd96hdp6hNqEfNSFe9OEe9ONcs2Occ2OccyXaMWXaMahXr+qVbiqVLizS7K8Qqu7QqvEO6bKNKH///8bCZhJAAAAeXRSTlMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAE4idC2E2K89OcuhNvKC0eo7OvTsBNkwr89ppEogtvyfBSYcDuk7cYyBqlLsPWFCQq37zAaWp3X9LlBGcXaFw0gBBLFvwIg3ZkosXQGbetMLbb2IQp90I0C7SgVjwAAAAFiS0dEkHgKjgwAAAAHdElNRQfmCB8PLyeIiZQMAAAAzUlEQVQY02NgYGRiZmFlY+fg5OLmUVBUUlZhQBJSVVPXqKzURAhpaetUgYAuVEhP38CwusbIuLa21gQsZGpmXldXV29haQWkrIFCNrZ2DQ0NjfYOjk5NQIYzg4urW3NLS0uru4enVwsIeDP4tIGAr59/QGA7iBUUzBASGhYeERnFGx3TAQaxcQx8/ALxCYmCScmdXWCQkgoSEhIWEU1L74aADDGYkHhmVk8vCGRLwIUkc3Lz+oAgXwohJC1TUNjfX1QsiywkV1JaVl4hDwCCbkiXFXla9gAAAABJRU5ErkJggg==" />
    </svg>
    <span style="font-size: 20px;font-weight: bold;text-align: center;color: #584fad" @click="turnFalse; Teleph_show = true">T E L E P H</span>
  </div>

</template>

<script>
import { baseSideShow, Teleph_show, drawer, turnFalse } from "@/api/api" ;
import { ref } from 'vue'
import {
  Menu,
  Fold
} from '@element-plus/icons-vue'
export default {
  name: 'BaseHeader',
  components: {
    Menu,
    Fold

  },
  setup(){
    const activeIndex = ref('')
    const handleSelect = (key, keyPath) => {
      console.log(key, keyPath)
    }


    return{
      activeIndex, handleSelect, baseSideShow, Teleph_show, drawer, turnFalse,
    }
  }
}
</script>
