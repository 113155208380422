<template>
  <!--        card-->
  <el-card :style="{ height: window_height - 45+'px' }">
    <template #header>
      <div class="card-header">
        <span style="font-weight: bolder;font-family: '仿宋'">群组搜索</span>
        <span v-show="channels.numFound" style="font-size: 13px;
        font-family: '仿宋';margin-left: 10px">(共搜索到：<span style="color: #9388f8">{{ channels.numFound  }}</span> 条)</span>
      </div>
    </template>
    <!--    search-->
    <el-input
        size="large"
        v-model="key_v"
        placeholder="①群标题关键词搜索 例：地推  ②群链接搜索 例：@tgzh_vip"
        class="input-with-select"
        @change="channel_blur"
    >
      <template #append>
        <el-icon @click="channel_blur"><Search /></el-icon>
      </template>
    </el-input>

    <el-table :stripe="true"  :data="channels.data" :scrollbar-always-on="true" style="width: 100%;margin-top: 20px" :max-height="window_height - 190">
      <el-table-column label="图片" width="80" >
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <el-image :src="scope.row.phImgUrl" :preview-teleported="true" :preview-src-list="[scope.row.phImgUrl]" style="width: 40px; height: 40px">
              <template #error>
                <el-image src="http://www.teleph.top/logo.png" :preview-teleported="true" :preview-src-list="['http://www.teleph.top/logo.png']"  style="width: 40px; height: 40px"/>
              </template>
            </el-image>
          </div>
        </template>

      </el-table-column>
      <el-table-column label="标题" width="420" >
        <template #default="scope">
          {{scope.row.type == 0 ? '📢': '👥'}}<span v-html="scope.row.title"></span>
        </template>
      </el-table-column>
      <el-table-column prop="id" label="ID" width="100"/>
      <el-table-column label="链接" width="250" >
        <template #default="scope">
          {{'@'}}
          <span v-html="scope.row.link.replace('https://t.me/', '')"></span>
        </template>
      </el-table-column>
      <el-table-column prop="num" label="人数" />
      <template #empty>
        <el-empty description="无数据" :style="{height: window_height - 230 +'px'}"/>
      </template>
    </el-table>
    <!--fen ye-->
    <div style="margin-top: 10px;text-align: center">
      <el-pagination
          v-model:currentPage="currentPage_channel"
          v-model:page-size="pageSize_channel"
          :page-sizes="[50, 100, 200, 300, 400]"
          :background="true"
          layout="sizes, prev, pager, next"
          :total="channels.numFound"
          @size-change="handleSizeChange_channel"
          @current-change="handleCurrentChange_channel"
      />
    </div>
  </el-card>

</template>

<script>
import {onMounted, ref, watch} from "vue";
import { Search,Plus} from '@element-plus/icons-vue'
import {axios_http, currentPage, orderID, orderTableData, pageSize, tableData, search_api, channels, window_width, window_height} from "../api/api";

export default {
  name: "channelSearch",
  components: {Search,Plus},
  setup(){
    const currentDate = ref(new Date().toLocaleDateString())


    const key_v = ref('')
    // const channels = ref([])
    const channel_type = ref(2)
    const currentPage_channel = ref(1)
    const pageSize_channel = ref(50)

    const handleSizeChange_channel = () => {
      search()
    }
    const handleCurrentChange_channel = () => {
      search()
    }

    const channel_blur = () => {
      console.log(key_v.value)
      currentPage_channel.value = 1
      search()
    }

    const search = () => {
      let method = 'search'
      if (key_v.value.startsWith('@')){
        method = 'tme_link_search'
      }

      let url = search_api +
          'solr/'+method+
          '/' + key_v.value +
          '/' + channel_type.value+
          '/' + pageSize_channel.value * (currentPage_channel.value - 1) +
          '/' + pageSize_channel.value
      axios_http('get', encodeURI(url),null, channels)
    }


    return{currentDate,  window_height,  key_v, channel_blur, channel_type,currentPage_channel,pageSize_channel,
      handleSizeChange_channel,handleCurrentChange_channel, channels  }
  }

}
</script>

<style scoped>
.time {
  font-size: 12px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  padding: 0;
  min-height: auto;
}

</style>