<template>
  <!--        card-->
  <el-card :style="{ height: window_height - 45+'px' }">
    <template #header>
      <div class="card-header">
        <span style="font-weight: bolder;font-family: '仿宋'">当日新用户</span>
         <span  v-show="newDeviceList.data.length > 0"  style="font-size: 13px;
         font-family: '仿宋';margin-left: 10px">(共搜索到：<span style="color: #9388f8">{{newDeviceList.data.length}}</span>条)</span>
        <!--riqi-->
        <el-date-picker
            style="width: 130px;margin-left: 20px"
            size="small"
            v-model="newDeviceDate"
            type="date"
            :placeholder="newDeviceDate"
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
            :clearable="false"
            :editable="false"
            @change="newDeviceDateChange"
        />
        <el-button type="primary"  size="small" :icon="Refresh" circle @click="selectMenu(selectedMenu)" :style="{float: 'right'}"/>
      </div>
    </template>

    <!--          tabe-0-->
    <el-table :data="newDeviceList.data" :stripe="true" :scrollbar-always-on="true" style="width: 100%;margin-bottom: 10px" :max-height="window_height - 80">
      <el-table-column  prop="id" label="ID" width="150"/>
      <el-table-column  prop="imei" label="设备号" width="280" />
      <el-table-column prop="createTime" label="创建时间"  :formatter="timeFormatter"/>
      <el-table-column prop="endTime" label="到期时间" :formatter="timeFormatter"/>
      <template #empty>
        <el-empty description="无数据" :style="{height: window_height - 170 +'px'}"/>
      </template>
    </el-table>

  </el-card>
</template>

<script>
import {ref} from "vue";
import {Refresh} from '@element-plus/icons-vue'
import {window_width, window_height, newDeviceList, newDeviceDate, newDeviceDateChange, timeFormatter,
  selectMenu, selectedMenu} from '@/api/api'

export default {
  name: "NewDevices",
  components: {
    Refresh
  },
  setup(){

    return{window_width, window_height, newDeviceList, newDeviceDate, newDeviceDateChange,
      selectMenu, selectedMenu, timeFormatter, Refresh}
  }
}
</script>

<style scoped>


</style>