<template>
  <!--        card-->
  <el-card :style="{ height: window_height - 45+'px' }">
    <template #header>
      <div class="card-header">
        <span style="font-weight: bolder;font-family: '仿宋'">过期用户</span>
        <span  v-show="expiredDeviceList.numFound > 0"  style="font-size: 13px;
        font-family: '仿宋';margin-left: 10px">(共搜索到：<span style="color: #9388f8">{{ expiredDeviceList.numFound}}</span> 条)</span>
        <el-button type="primary"  size="small" :icon="Refresh" circle @click="selectMenu(selectedMenu)" :style="{float: 'right'}"/>
      </div>
    </template>

    <!--          tabe-0-->
    <el-table :data="expiredDeviceList.data" :stripe="true" :scrollbar-always-on="true" style="width: 100%;margin-bottom: 10px" :max-height="window_height - 130">
      <el-table-column  prop="id" label="ID" width="150">
        <template #header>
          <span style="float: left;margin-top: 1px">
            ID
          </span >
          <DCaret style="width: 1.1em; height: 1.1em; margin-left: 8px;margin-top: 5px" @click="reloadList"/>
        </template>
      </el-table-column>
      <el-table-column  prop="imei" label="设备号" width="280" />
      <el-table-column prop="createTime" label="创建时间"  :formatter="timeFormatter"/>
      <el-table-column prop="endTime" label="到期时间" :formatter="timeFormatter"/>
      <template #empty>
        <el-empty description="无数据"  :style="{height: window_height - 170 +'px'}"/>
      </template>
    </el-table>

    <!--fen ye-->
    <div style="margin-top: 10px;text-align: center">
      <el-pagination
          v-model:currentPage="expired_currentPage"
          v-model:page-size="expired_pageSize"
          :page-sizes="[50, 100, 200, 300, 400]"
          :background="true"
          layout="sizes, prev, pager, next"
          :total="expiredDeviceList.numFound"
          @size-change="expired_handleSizeChange"
          @current-change="expired_handleCurrentChange"
      />
    </div>
  </el-card>
</template>

<script>
import {ref} from 'vue'

import {
  DCaret,
    Refresh
} from '@element-plus/icons-vue'
import {window_width, window_height, timeFormatter, expiredDeviceList, reloadList, selectMenu, selectedMenu,
  expired_handleCurrentChange, expired_currentPage, expired_pageSize, expired_handleSizeChange,} from '../api/api'
export default {
  name: "ExpiredDevice",
  components: {DCaret, Refresh},
  setup(){

    return{window_width, window_height, expiredDeviceList, reloadList, Refresh, selectMenu, selectedMenu,
      timeFormatter,expired_currentPage, expired_pageSize,  expired_handleSizeChange, expired_handleCurrentChange}
  }
}
</script>

<style scoped>

</style>