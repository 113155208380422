<template>
  <el-menu
      default-active="1"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      @select="selectMenu"
      :style="{ height: '100%'}"
  >
    <el-menu-item index="0" style="padding: 0; height: 40px">
      <!--      <el-icon><Expand /></el-icon>-->
      <template #title>
        <!--        <svg-->
        <!--            style="margin-right: 8px;"-->
        <!--            xmlns="http://www.w3.org/2000/svg"-->
        <!--            xmlns:xlink="http://www.w3.org/1999/xlink"-->
        <!--            width="18px" height="15px">-->
        <!--          <image  x="0px" y="0px" width="18px" height="15px"  xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPCAMAAADeWG8gAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABs1BMVEWRbsqMc86Jd9CFe9OAf9Z8g9l5htx2it5yjuBvkOJslOVpl+dmmemaZMObZMOfYMCiXb+iXb6kW72mWbuhXr+sU7ezTbO1SrC5Rq+3R6/DO6a+QKrDPKbJNqLHN6PKNaFjnetinuxgoO1goO1goO1pl+dmmellm+plm+tlm+pvkOJslORrleZrlOV5htx2it50jd9zjN9zjd9zjN90jeCAf9Z8g9l7hNp6hdt8g9p7hNl6hduJd9CGetKEe9SDfNSEetOFetOFetODfNSRbsqOccyNcs2Nc82Occ2UbMiPcMyNcs2XZ8aYZ8WVasiVasidYsKZZsSXaMWUa8ibY8OfYMChXr+iXb+iXb+hXr+dYsKmWbqiXb6hXr+hXr+kW72mWbujXL6sU7asUreqVLipVbm0S7G0S7KzS7K3R668Qqy6RKy+QKrDPabEO6bDO6bHN6PKNKHKNKHJNqLMM6DPL57PL57OMJ7KNaFlm+prleZzjeBzjN9zjd96hdp6hNqEfNSFe9OEe9ONcs2Occ2OccyXaMWXaMahXr+qVbiqVLizS7K8Qqu7QqvEO6bKNKH///8bCZhJAAAAeXRSTlMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAE4idC2E2K89OcuhNvKC0eo7OvTsBNkwr89ppEogtvyfBSYcDuk7cYyBqlLsPWFCQq37zAaWp3X9LlBGcXaFw0gBBLFvwIg3ZkosXQGbetMLbb2IQp90I0C7SgVjwAAAAFiS0dEkHgKjgwAAAAHdElNRQfmCB8PLyeIiZQMAAAAzUlEQVQY02NgYGRiZmFlY+fg5OLmUVBUUlZhQBJSVVPXqKzURAhpaetUgYAuVEhP38CwusbIuLa21gQsZGpmXldXV29haQWkrIFCNrZ2DQ0NjfYOjk5NQIYzg4urW3NLS0uru4enVwsIeDP4tIGAr59/QGA7iBUUzBASGhYeERnFGx3TAQaxcQx8/ALxCYmCScmdXWCQkgoSEhIWEU1L74aADDGYkHhmVk8vCGRLwIUkc3Lz+oAgXwohJC1TUNjfX1QsiywkV1JaVl4hDwCCbkiXFXla9gAAAABJRU5ErkJggg==" />-->
        <!--        </svg>-->
        <!--        <span   style="font-size: 20px;font-weight: bold;text-align: center;color: #584fad">TELEPH</span>-->
        <el-image style="width: 126px; height: 140px" :src="require('../assets/top.png')" fit="contain" />
      </template>
    </el-menu-item>
    <el-menu-item index="1" @click="drawer = false">
      <el-icon><Monitor /></el-icon>
      <template #title >设备续费</template>
    </el-menu-item>
    <el-menu-item index="2" @click="drawer = false">
      <el-icon><CollectionTag /></el-icon>
      <template #title >订单查询</template>
    </el-menu-item>
    <el-menu-item index="3" @click="drawer = false">
      <el-icon><ToiletPaper /></el-icon>
      <template #title >订单记录</template>
    </el-menu-item>
    <el-menu-item index="4" @click="drawer = false">
      <el-icon><Folder /></el-icon>
      <template #title >群组搜索</template>
    </el-menu-item>
    <el-menu-item index="5" @click="drawer = false">
      <el-icon><DataLine /></el-icon>
      <template #title >日新用户</template>
    </el-menu-item>
    <el-menu-item index="6" @click="drawer = false">
      <el-icon><TurnOff /></el-icon>
      <template #title >过期用户</template>
    </el-menu-item>
    <el-menu-item v-if="AppConfigViewShow" index="7" @click="drawer = false">
      <el-icon><Setting /></el-icon>
      <template #title >App配置</template>
    </el-menu-item>
    <el-menu-item index="8" @click="drawer = false">
      <el-icon><Odometer /></el-icon>
      <template #title >线路列表</template>
    </el-menu-item>
  </el-menu>
</template>

<script >
import { ref ,watch, onMounted} from 'vue'
import {
  CollectionTag,
  ToiletPaper,
  Folder,
  DataLine,
  Monitor,
  TurnOff,
  Expand,
    Setting, Odometer
} from '@element-plus/icons-vue'
import {selectMenu, window_width, window_height, checkLogin, baseSideShow, drawer, AppConfigViewShow} from "../api/api" ;
export default {
  name: 'BaseSide',
  components: {
    CollectionTag,
    ToiletPaper,
    Folder,
    DataLine,
    Monitor,
    TurnOff,
    Setting,
    Odometer
  },
  setup(){
    const isCollapse = ref(true)
    const handleOpen = (key, keyPath) => {
      // console.log('handleOpen',key, keyPath)
      // checkLogin()
    }
    const handleClose = (key, keyPath) => {
      // console.log('handleClose',key, keyPath)
    }

    return{
      isCollapse, handleOpen, handleClose, window_width, window_height, selectMenu, baseSideShow, drawer, AppConfigViewShow
    }
  }
}


</script>

