<template>
  <!--        card-->
  <el-card :style="{ height: window_height - 45+'px' }">
    <template #header>
      <div class="card-header">
        <el-button type="danger" v-if="deleteProxies_list.length > 0" size="small" :icon="DeleteFilled"
                   @click="deleteProxies(deleteProxies_list)" :style="{float: 'left'}">
          全部删除
        </el-button>
        <span style="font-weight: bolder;font-family: '仿宋'">线路列表</span>
        <span  v-show="proxies.length > 0"  style="font-size: 13px;
         font-family: '仿宋';margin-left: 10px">(共搜索到：<span style="color: #9388f8">{{proxies.length}}</span>条)</span>
        <el-button type="primary"  size="small" :icon="Refresh" circle @click="selectMenu(selectedMenu)" :style="{float: 'right'}"/>
        <el-button type="success"  size="small" @click="addProxiesDialogShow = true" :icon="Plus" :style="{float: 'right', marginRight: '20px'}"/>
      </div>
    </template>

    <!--          tabe-0-->
    <el-table :data="proxies" :stripe="true" :scrollbar-always-on="true"
              @selection-change="handleSelectionChange" :default-sort="{ prop: 'seconds.seconds', order: 'ascending' }"
              style="width: 100%;margin-bottom: 0" :max-height="window_height - 140">
      <el-table-column type="selection" width="55" :selectable="canSelect"/>
      <el-table-column  prop="id" label="ID" width="50"/>
      <el-table-column  prop="server" label="IP" min-width="180" >
        <template #default="scope">
          <el-icon v-if="!scope.row.seconds" color="#F56C6C"><SwitchButton  /> </el-icon>
          <el-icon v-else-if="scope.row.mobile" color="#409EFF"><Iphone  /> </el-icon>
          <el-icon v-else-if="!scope.row.mobile" color="#67C23A"><Link  /> </el-icon>
          <span :style="{marginLeft: '5px'}">{{scope.row.server}}</span>
        </template>
      </el-table-column>
      <el-table-column  prop="port" label="端口" min-width="80" />
      <el-table-column label="类型" width="100" >
        <template #default="scope">
          <el-tag v-if="scope.row.type.secret">MTP</el-tag>
          <el-tag v-else-if="scope.row.type.httpOnly" type="success">HTTP</el-tag>
          <el-tag v-else type="warning">SOCKS</el-tag>
        </template>
      </el-table-column>
      <el-table-column  prop="seconds.seconds" label="延迟" min-width="80" >
        <template #default="scope">
          <el-text class="mx-1" :type="secondsColor(scope)">{{secondsFormatter(scope)}}</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="isEnabled" label="启用状态" min-width="150">
        <template #default="scope">

          <el-switch
              v-if="scope.row.seconds"
              v-model="scope.row.isEnabled"
              :disabled="scope.row.isEnabled"
              inline-prompt
              active-text="已启用"
              inactive-text="未启用"
              size="default"
              @change="onSwitchChange(scope.row.id)"
          />
          <el-text class="mx-1"  v-else type="danger">————</el-text>
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="180">
        <template #default="scope">
          <el-button link type="primary" size="default" @click="editProxyHandler(scope)">修改</el-button>
          <el-button link type="primary" @click="deleteProxies([scope.row.id])" size="default">删除</el-button>
          <!--          <el-button link type="primary" v-if="scope.row.seconds" size="small">启用</el-button>-->
          <el-button link type="primary" v-if="scope.row.httpUrl" id="copyLink"
                     @click="copy('#copyLink', scope.row.httpUrl.url)" size="default">复制链接</el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-empty description="无数据" :style="{height: window_height - 170 +'px'}"/>
      </template>
    </el-table>

  </el-card>

  <el-dialog v-model="addProxiesDialogShow" title="添加线路">
    <el-input
        v-model="addProxies"
        :rows="2"
        type="textarea"
        autosize
        placeholder="一行一条线路"
    />
    <el-button color="#626aef" :style="{marginTop: '10px', width: '48%'}" @click="addProxies = []" :dark="true">清除</el-button>
    <el-button color="#626aef" :style="{marginTop: '10px', width: '48%'}" @click="submitProxies" :dark="true">提交</el-button>
  </el-dialog>

  <el-dialog v-model="editProxyDialogShow" title="修改线路">
    <el-form :model="editProxy" >
      <el-form-item label="地址">
        <el-input v-model="editProxy.server" />
      </el-form-item>
      <el-form-item label="端口">
        <el-input v-model="editProxy.port" />
      </el-form-item>
      <el-form-item label="类型">
        <el-form :model="editProxy._proxyType" :style="{width: '100%'}"  label-position='right'>
          <el-form-item label="secret" :style="{marginBottom: '15px'}">
            <el-input v-model="editProxy._proxyType.secret" />
          </el-form-item>
          <el-form-item label="username" :style="{marginBottom: '15px'}">
            <el-input v-model="editProxy._proxyType.username" />
          </el-form-item>
          <el-form-item label="password">
            <el-input v-model="editProxy._proxyType.password" />
          </el-form-item>
        </el-form>
      </el-form-item>
    </el-form>
    <el-button color="#626aef" :style="{marginTop: '10px', width: '100%'}" @click="editProxySubmit" :dark="true">提交</el-button>
  </el-dialog>
</template>

<script>
import {Plus, Refresh, DeleteFilled, Iphone, Link, SwitchButton} from '@element-plus/icons-vue'
import {
  addProxies,
  addProxiesDialogShow,
  copy,
  deleteProxies,
  proxies,
  selectedMenu,
  selectMenu,
  submitProxies,
  window_height,
  window_width,
  enableProxy,
  editProxyDialogShow, editProxy,
  editProxySubmit, editProxyHandler,
  pingProxiesHttp, deleteProxies_list
} from '@/api/api'
import {onMounted, ref} from "vue";
import { ElTable } from 'element-plus'
import Axios from "axios";

export default {
  name: "ProxiesView",
  components: {
    Refresh, Plus, DeleteFilled, Iphone, Link, SwitchButton
  },
  setup(){

    const secondsColor = (scope) => {
      if (! scope.row.seconds){
        return 'danger'
      }
      let sec_int = parseInt(scope.row.seconds.seconds * 1000)
      return sec_int < 100 ? 'success' : sec_int < 300 ? 'primary' : sec_int < 800 ? 'warning' : 'danger'
    }
    const secondsFormatter = (scope) => {
      if (! scope.row.seconds){
        return '—'
      }
      return parseInt(scope.row.seconds.seconds * 1000)
    }

    const onSwitchChange = (id) => {
      enableProxy({
        'value': id
      })
    }

    setInterval(function() {
      pingProxiesHttp()
      // console.log("这个消息每隔20000毫秒（20秒）就会打印出来");
    }, 60 * 1000 * 2);


    const handleSelectionChange = (val) => {
      let l = []
      val.forEach(v => {
        l.push(v.id)
      })
      deleteProxies_list.value = l
    }

    const canSelect = (row, index) => {
      return !row.isEnabled
    }


    return{window_width, window_height, proxies, Refresh, DeleteFilled, selectMenu, selectedMenu, secondsFormatter, Plus, secondsColor, copy,
      deleteProxies, addProxiesDialogShow, addProxies, submitProxies, enableProxy, onSwitchChange, editProxyDialogShow, editProxy, Iphone,
      editProxySubmit, editProxyHandler, handleSelectionChange, deleteProxies_list, canSelect, }
  }
}
</script>

<style scoped>

</style>