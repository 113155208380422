const CryptoJS  = require('crypto-js');

const secretKey = '6162382d323fb399fc202a5aca55336a';

export function encrypt(plainText) {
    const iv = generateIV(); // 生成初始化向量
    const key = CryptoJS.enc.Utf8.parse(secretKey);

    // 使用 AES-CBC 模式和 PKCS5Padding 填充进行加密
    const encrypted = CryptoJS.AES.encrypt(plainText, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

    // 将初始化向量和加密后的数据合并
    const combined = iv.concat(CryptoJS.enc.Hex.parse(encrypted.ciphertext.toString()));

    // 返回加密后的Base64字符串
    return CryptoJS.enc.Base64.stringify(combined);
}

function generateIV() {
    const iv = CryptoJS.lib.WordArray.random(16); // 16 字节为初始化向量长度
    return iv;
}

export function decrypt(encryptedText) {
    const combined = CryptoJS.enc.Base64.parse(encryptedText);
    // console.log('ciphertext',encryptedText, 'secretKey',secretKey,'combined',combined)
    const iv = combined.clone();
    iv.sigBytes = 16; // 16 字节为初始化向量长度
    iv.clamp();

    const key = CryptoJS.enc.Utf8.parse(secretKey);
    // console.log('key',key,)
    // 使用 AES-CBC 模式和 PKCS5Padding 填充进行解密
    const ciphertext = combined.words.slice(4); // 移除初始化向量部分
    const encrypted = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.lib.WordArray.create(ciphertext),
    });

    const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });
    // console.log('decrypted',decrypted,)
    // 返回解密后的UTF-8字符串
    return decrypted.toString(CryptoJS.enc.Utf8);
}