<template>
  <Login v-show="index_show"/>

  <!-- container -->
  <div class="common-layout" v-show="container_show" :style="{minWidth: '2160px'}">
    <el-container >

      <el-header :style="{padding: 0}" height="32px"><BaseHeader/> </el-header>
      <el-container>
<!--        <el-aside v-if="baseSideShow" width="150px"> <BaseSide/> </el-aside>-->
        <el-main :style="{ height: window_height +'px' }">
          <TelephView v-if="Teleph_show"/>
          <HelloWorld v-if="HelloWorld_show"/>
          <OrderSearch v-if="OrderSearch_show"/>
          <Orders v-if="Orders_show"/>
          <channel_Search v-if="channelSearch_show"/>
          <NewDevices v-if="NewDevices_show"/>
          <ExpiredDevice v-if="ExpiredDevice_show"/>
          <appBackView v-if="appBackView_show && AppConfigViewShow"/>
          <proxiesView v-if="ProxiesView_show"/>
        </el-main>
      </el-container>

    </el-container>

    <el-drawer
        v-model="drawer"
        :show-close="false"
        :direction="direction"
        size="125.5"
        :with-header=false
    >
      <template #default>
        <BaseSide />
      </template>
    </el-drawer>
  </div>


</template>

<script >
// @ is an alias to /src
import Login from "../components/Login";
import TelephView from "../components/TelephView";
import HelloWorld from '../components/HelloWorld'
import BaseSide from "../layouts/BaseSide"
import BaseHeader from "../layouts/BaseHeader";
import OrderSearch from "../components/orderSearch";
import Orders from '../components/orders'
import channel_Search from "../components/channelSearch";
import NewDevices from '../components/NewDevices'
import ExpiredDevice from '../components/ExpiredDevice'
import appBackView from '../components/appBackView.vue'
import proxiesView from '../components/ProxiesView.vue'

import {OrderSearch_show, HelloWorld_show, Orders_show, channelSearch_show, NewDevices_show, drawer, appBackView_show,
  window_width, window_height, ExpiredDevice_show, Teleph_show, index_show, container_show, checkLogin, baseSideShow,
  AppConfigViewShow, ProxiesView_show} from "../api/api" ;
import {onMounted, ref, watch} from 'vue'
import {ElMessageBox} from "element-plus";

export default {
  name: 'Home',
  components: {
    Login,
    TelephView,
    OrderSearch,
    HelloWorld,
    BaseSide,
    BaseHeader,
    Orders,
    channel_Search,
    NewDevices,
    ExpiredDevice,
    appBackView,
    proxiesView
  },
  setup(){

    const drawerStyle = ref({
      // padding: 0
    elDrawerPaddingPrimary: 0

    })
    const drawer2 = ref(false)
    const direction = ref('ltr')
    const radio1 = ref('Option 1')
    //
    // console.log(OrderSearch_show.value, HelloWorld_show.value, Orders_show.value,
    //     channelSearch_show.value, NewDevices_show.value, drawer.value, appBackView_show.value,)
    // const handleClose = (done: () => void) => {
    //   ElMessageBox.confirm('Are you sure you want to close this?')
    //       .then(() => {
    //         done()
    //       })
    //       .catch(() => {
    //         // catch error
    //       })
    // }

  //   const el_drawer__body = {
  //   --el-drawer-padding-primary: var(--el-dialog-padding-primary, 0);
  // }

    // function cancelClick() {
    //   drawer2.value = false
    // }
    // function confirmClick() {
    //   ElMessageBox.confirm(`Are you confirm to chose ${radio1.value} ?`)
    //       .then(() => {
    //         drawer2.value = false
    //       })
    //       .catch(() => {
    //         // catch error
    //       })
    // }

    const handleScrollx = () => {
      window_width.value = document.documentElement.clientWidth
      window_height.value = document.documentElement.clientHeight - 45;
    }

    watch(
        () => document.documentElement.clientHeight,
        (pev, old) => {
          window.addEventListener("resize", handleScrollx, true);
        }
    )

    onMounted(() => {
      window.addEventListener("resize", handleScrollx, true)
      checkLogin()
    })




    return{
      OrderSearch_show, HelloWorld_show, Orders_show, channelSearch_show, index_show, container_show, NewDevices_show,
      ExpiredDevice_show, Teleph_show, window_height, window_width, baseSideShow, appBackView, appBackView_show,
      drawer, drawer2, direction, radio1, drawerStyle, AppConfigViewShow, ProxiesView_show,
    }
  }

}

</script>

<style scoped>
/deep/ .el-drawer__body {
  padding: 0 !important;
}
</style>
